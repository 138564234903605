<template>
  <div v-if="page" class="pb-3">
    <b-row>
      <b-col cols="9">
        <h1>{{ page.name }}</h1>
      </b-col>
      <b-col v-if="page.isAllowedToEdit" cols="3" class="text-right my-auto">
        <!-- Open sidebar. -->
        <b-button
          variant="primary"
          @click="
            sidebar.visible = !sidebar.visible;
            sidebar.isEditMode = !sidebar.isEditMode;
          "
        >
          <font-awesome-icon
            class="d-none d-sm-inline-block text-dark mr-2"
            icon="pen-square"
            size="lg"
          />
          Redigera
        </b-button>
        <!-- ./ Open sidebar. -->
      </b-col>
    </b-row>

    <div class="page" v-html="page.content" />
    <FileList v-if="!isLoading"
              entity-type="archive" 
              :entity-id="id"
              title="Bilagor" />
    <div class="text-right mt-5">
      <em>Senast ändrad
        {{ (page.edited || page.created) | datetime('yyyy-MM-dd HH:mm') }}
      </em>
    </div>

    <GenericSidebar
      :visible.sync="sidebar.visible"
      :is-edit-mode.sync="sidebar.isEditMode"
      :fields="sidebar.fields"
      :item="sidebar.selected"
      width-when-editing="800px"
      @update:visible="getPage()"
      @update:updateData="update()"
    >
      <template v-slot:header>
        <span v-if="sidebar.selected" />
      </template>
      <template v-slot:edit>
        <SaveArchive :id="id" 
                     :parent-id="parentId"  />
      </template>
    </GenericSidebar>
  </div>
</template>

<script>
import { get } from '@/helpers/api';
import FileList from '@/components/file/FileList';
import GenericSidebar from '@/components/GenericSidebar';
import SaveArchive from '@/components/archive/SaveArchive';

export default {
  name: 'ArchivePage',
  components: {
    FileList,
    GenericSidebar,
    SaveArchive
  },
  data() {
    return {
      isLoading: true,
      parentId: 0,
      page: null,
      sidebar: {
        selected: null,
        visible: false,
        isEditMode: false
      }
    };
  },
  computed: {
    id() {
      return typeof this.$route.params.id !== 'undefined'
        ? parseInt(this.$route.params.id)
        : 0;
    }
  },
  watch: {
    $route: 'getPage'
  },
  async mounted() {
    await this.getPage();
  },
  methods: {
    update(){
       this.getPage();
    },
    async getPage() {
      this.isLoading = true;
      await get('Archive', `GetPage/${this.id}`)
        .then((x) => {
            this.page = x.data;
            this.parentId = x.data.parentId;
            this.isLoading = false;
        })
        .catch((x) => {
          this.$router.push({
            name: 'error',
            params: {
              statusCode: 404,
              statusText: 'Sidan kunde inte hittas.'
            }
          });
        });
    }
  }
};
</script>

<style>
.page img {
  max-width: 100%;
}
</style>
