<template>
  <div v-if="files.length > 0">
    <div class="d-flex justify-content-between align-items-center">
      <h4>{{ title }}</h4>
      <b-badge variant="info" style="font-size: 100%">
        {{ files.length }} st
      </b-badge>
    </div>
    <b-list-group>
      <b-list-group-item
        v-for="file in files"
        :key="file.id"
        role="button"
        class="d-flex justify-content-between align-items-center"
        @click.prevent="getFile(file.id)"
      >
        <span>
          <font-awesome-icon :icon="file.fileExtension | getIcon()" />
          {{ file.fileName }}

        <small v-if="file.description ">
            {{ file.description }}
        </small>
        </span>
        <span>
          {{ file.fileSize | formatFileSize }}
        </span>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import { get } from "@/helpers/api";
import { ENTITY_TYPES } from "@/helpers/entity-type.helper";

export default {
  name: "FileList",
  props: {
    entityType: String,
    entityId: Number,
    title: String,
  },
  data() {
    return {
      files: [],
    };
  },
  watch: {
    entityType(oldValue, newValue) {
      if (oldValue !== newValue) this.getFiles();
    },
    entityId(oldValue, newValue) {
      if (oldValue !== newValue) this.getFiles();
    },
  },
  mounted() {
    this.getFiles();
  },
  methods: {
    async getFile(id) {
      window.open(
        `${process.env.VUE_APP_ROOT_API}File/Download/${id}`,
        "_blank",
        ""
      );
    },
    async getFiles() {
        await get("File", `GetAllBy/${(ENTITY_TYPES[this.entityType].id)}/${this.entityId}`).then(
        (x) => (this.files = x.data)
      );
    }
  }
};
</script>
